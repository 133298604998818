.DetailAccordion {
  &.DetailAccordion-expanded {
    .DetailAccordionSummary {
      &.Mui-focused {
        background-color: inherit;
      }
    }
  }
}

div[role="region"] {
  height: 100%;
}

@media only screen and (max-width: 768x) {
  .DetailAccordion {
    flex-direction: column;
  }
}
