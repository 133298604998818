.attachments-dnd {
  background-color: #f4f5f7;
  padding: 16px;
  border: 1px dashed #e5e7eb;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-right: 4px;

  &.active {
    background-color: darken($color: #f4f5f7, $amount: 4%);
  }
}
