.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiToolbar-regular .MuiFormControl-root .MuiInput-underline:before {
  display: none !important;
}

.MuiInput-root .MuiInputAdornment-positionStart {
  color: #9e9e9e;
}
