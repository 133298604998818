.fc .fc-button-primary {
  background-color: #1a0b31;
  border: none;
}

.fc .fc-button-primary:hover {
  background-color: #0b8d9b;
  border: none;
}

.fc .fc-button-primary:disabled {
  background-color: #1a0b31;
  opacity: 0.25;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #0b8d9b;
}
