.HoverActionContainer {
  position: relative;

  .HoverActions {
    display: none;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &:hover .HoverActions {
    display: flex;
  }
}
