body {
  background-color: #f5f5f5;
  overflow-x: hidden;
}

.MuiInput-underline:before {
  border-color: #e5e7eb !important;
}

.MuiAppBar-colorPrimary {
  background: #fff !important;
}

.MuiDrawer-root {
  .MuiDrawer-paper {
    &:not(.MuiDrawer-paperAnchorRight) {
      background: #1a0b31;
      color: #fff;
      .MuiListItemIcon-root {
        color: #fff;
      }
      .MuiListItem-root {
        &:hover {
          background: #34254b;
        }
        &.Mui-selected {
          background: #0b8d9b;
        }
      }
    }
  }
}

.MuiPaper-rounded.MuiAccordion-root:before {
  background-color: transparent !important;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #e5e7eb;
  cursor: text;
  padding: 16px;
  border-radius: 4px;
  font-family: AvenirNext-Medium;
  font-size: 14px;
}
.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.vertical-timeline::before {
  background: #3f51b5 !important;
}

.page-action-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

ul.plain {
  list-style: none;
  padding-left: 0;
}

.message-inbox {
  .MuiToolbar-root {
    display: none;
  }
  tr.MuiTableRow-head {
    display: none;
  }
}

// Quill
.ql-editor {
  min-height: 200px;
}

.MuiList-root.actions-on-hover {
  .MuiListItem-container {
    .MuiListItemSecondaryAction-root {
      display: none;
    }
    &:hover {
      .MuiListItemSecondaryAction-root {
        display: initial;
      }
    }
  }
}

.MuiTableRow-root {
  .table-row-action {
    visibility: hidden;
  }
  &:hover {
    .table-row-action {
      visibility: initial;
    }
  }
}

@media only screen and (max-width: 960px) {
  .MuiTableRow-root {
    .table-row-action {
      visibility: initial;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
}

.hiddenActionsRow {
  .MuiButtonBase-root {
    visibility: hidden;
  }
  &:hover {
    .MuiButtonBase-root {
      visibility: visible;
    }
  }
}

.text-divider {
  display: flex;
  flex-direction: row;
  color: #9e9e9e;
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #9e9e9e;
    margin: auto;
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
}

.ql-mergeFields .ql-picker-label:before {
  content: "Fields";
  padding-right: 18px;
}
.ql-mergeFields .ql-picker-item:before {
  content: attr(data-value);
}

.ql-mergeFields {
  .ql-picker-options {
    max-height: 175px;
    overflow: auto;
  }
}

.ql-recipes {
  & .ql-picker-label:before {
    content: "Recipes";
    padding-right: 18px;
  }

  & .ql-picker-item:before {
    content: attr(data-value);
  }

  .ql-picker-options {
    max-height: 175px;
    overflow: auto;
  }
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 4px 0px rgba(26, 11, 49, 0.2);
}

.ql-mention-list-container {
  //width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 18px;
  font-size: 14px;
  padding: 10px 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #5759ff;
  color: #fff;
  text-decoration: none;
}

.mention {
  display: inline-block;
  border-radius: 6px;
  background-color: #5759ff;
  color: #fff;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
