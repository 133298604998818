@media screen and (max-width: 724px) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }

  .fc .fc-toolbar-title {
    margin: 5px;
  }
}

@media screen and (max-width: 375px) {
  .fc-toolbar-chunk {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .fc .fc-button {
    margin-bottom: 5px;
  }

  .fc .fc-button-group {
    margin-right: 0.75em;
  }
}
