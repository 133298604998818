body {
  font-family: AvenirNext-Regular, Avenir, sans-serif;
}
@media print {
  .MuiBox-root {
    display: block;
    page-break-inside: avoid;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #eeeeee;
  }

  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }
  .MuiPaper-root {
    display: block;
    page-break-inside: avoid;
  }
  @page {
    margin: 0.75cm 0.25cm;
  }
}
