.TaskListAccordion {
  .MuiCollapse-container {
    height: calc(100% - 56px);
    overflow-y: auto;
    overflow-x: hidden;

    .MuiCollapse-wrapper {
      height: 100%;

      .MuiCollapse-wrapperInner {
        height: 100%;
      }
    }
  }
}
